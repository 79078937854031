<template>
  <div class="US_content">
    <!-- <ComHeader :imgs="img" :titleConent="titleConent" class="ComHeader" /> -->
    <!-- 头部 -->
    <div class="headImg">
      <img :src="url" alt="" />
      <!-- 标题页 -->
      <div class="title">
        <commonT :value="titleConent" class="commonT" />
      </div>
    </div>

    <!-- 公司简介 -->
    <div class="compantIntro">
      <p>
        {{ webInfo.webCorporateIntroduce }}
      </p>
      <div class="content">
        <div class="serveLeft same">
          <h4>客服服务</h4>
          <p>联系电话:{{ webInfo.webCorporatePhone }}</p>
          <p>Email:{{ webInfo.webCorporateEmail }}</p>
        </div>
        <div class="cooperRight same">
          <h4>商务合作</h4>
          <p>联系人:{{ webInfo.webCorporateName }}</p>
          <p>Email:{{ webInfo.webCorporateEmail }}</p>
        </div>
      </div>
      <div class="img">
        <img :src="mapurl" alt="" />
      </div>

      <div class="message">
        <div class="MsgTitle">
          <h3>留言板</h3>
          <p>Message Board</p>
        </div>
        <div class="msgInfo">
          <div class="inputLeft">
            <input placeholder="姓名" v-model="inputvalue_one" />
            <input
              type="number"
              placeholder="手机号码"
              v-model="inputvalue_two"
            />
          </div>
          <div class="inputRight">
            <textarea
              placeholder="如何加盟私脸定治，加盟费是多少……"
              v-model="textareaVlaue"
              rows="4"
              cols="20"
            />
          </div>
        </div>
        <div class="btn">
          <van-button type="info" @click="sendMessage" size="small"
            >留言</van-button
          >
        </div>
        <van-overlay :show="loadtype">
          <div class="wrapper">
            <van-loading color="#0094ff" class="loading"/>
          </div>
        </van-overlay>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>
<script>
export default {
  name: "M-contactUS",
  data() {
    return {
      url: require("../../assets/backImg.jpg"),
      titleConent: {
        name: "联系我们",
        ename: "contact",
      },
      webInfo: {},
      mapurl: require("../../assets/address.png"),
      inputvalue_one: "",
      inputvalue_two: "",
      textareaVlaue: "",
      loadtype: false,
    };
  },
  created() {
    this.getcompanyInfo();
  },
  methods: {
    //获得初始数据
    async getcompanyInfo() {
      let result = await this.$api.getCompanyInfo();
      if (result.code == 0) {
        this.webInfo = result.data;
      }
      // console.log(result);
    },
    //发送留言信息
    async sendMessage() {
      this.loadtype = true;
      console.log(this.inputvalue_one, this.inputvalue_two, this.textareaVlaue);
      if (!this.inputvalue_one || !this.inputvalue_two || !this.textareaVlaue) {
        this.loadtype = false;
        this.$toast("提交失败，请将信息填写完整");
        return;
      }
      let bol=this.checkMobile(this.inputvalue_two)
      if(!bol){
        this.loadtype = false;
        this.$toast('手机号码格式错误，请重新输入！')
        return
      }
      let data = {
        msgName: this.inputvalue_one,
        msgPhone: this.inputvalue_two,
        msgContent: this.textareaVlaue,
      };

      let result = await this.$api.sendMessage(data);
      if (result.code == 0) {
        this.loadtype=false
        this.$toast({
          message: "留言提交成功",
          icon: "checked",
          duration: 1000,
        });
        this.inputvalue_one = "";
        this.inputvalue_two = "";
        this.textareaVlaue = "";
      } else {
        this.loadtype = false;
        this.$toast.fail("留言失败，请重试！");
      }
    },
    //验证手机号
    checkMobile(phoneNumber){
        let res=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if(res.test(phoneNumber)){
          return true
        }else{
          return false
        }
    }
  },
};
</script>
<style lang="scss" scoped>
.US_content {
  height: 100%;
  .headImg {
    position: relative;
    width: 100%;
    height: 165px;
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 54px;
      box-sizing: border-box;
      background-color: #fff;
      .commonT {
        width: 100%;
        & /deep/ .title {
          margin-top: 50px;
        }
        & /deep/ p {
          text-align: center !important;
          &:first-of-type {
            font-size: 15px;
            margin-top: 115px !important;
          }
          &:last-of-type {
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .compantIntro {
    width: 90%;
    margin: 0 auto;
    margin-top: 60px;
    box-sizing: border-box;
    p {
      padding: 0 20px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      text-align: left;
      text-indent: 2em;
    }
    .content {
      width: 100%;
      h4 {
        text-align: left;
        padding: 0 20px;
        font-size: 15px;
      }
    }
    .img {
      width: 100%;
      margin: 10px auto;
      img {
        width: 100%;
        height: 200px;
      }
    }

    .message {
      height: 250px;
      .MsgTitle {
        h3 {
          font-weight: normal;
          font-size: 17px;
          margin-bottom: 0;
          text-align: center;
        }
        p {
          color: #999;
          margin: 0;
          padding: 0;
          font-size: 13px;
          margin-bottom: 20px;
          text-indent: 0;
          text-align: center;
        }
      }
    }

    .msgInfo {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .inputLeft {
        margin-right: 30px;
        input {
          height: 20px;
          line-height: 20px;
          &:first-of-type {
            margin-bottom: 10px;
          }
        }
      }
    }
    .btn {
      margin-top: 20px;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .loading{
     width:30px;
     height: 30px;
     background-color:#2c3e50;
  }
  
}
</style>